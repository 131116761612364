export default {
  async update (Vue, formData) {
    let apiCalls = []
    for (const fieldname in formData) {
      let values = {}
      if (fieldname.startsWith('valor_numerico_')) {
        values.iddato = parseInt(fieldname.substring('valor_numerico_'.length))
        values.valor_numerico = formData[fieldname]
      } else if (fieldname.startsWith('valor_fecha_')) {
        values.iddato = parseInt(fieldname.substring('valor_fecha_'.length))
        values.valor_fecha = formData[fieldname]
      } else if (fieldname.startsWith('valor_')) {
        values.iddato = parseInt(fieldname.substring('valor_'.length))
        values.valor = formData[fieldname]
      }
      if (values.iddato) {
        apiCalls.push({
          name: 'datoUpdate',
          method: 'dato.update',
          params: { values },
        })
      }
    }
    await Vue.$api.batchCall(apiCalls)
  },
}
